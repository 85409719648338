import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { RECIPIENT, SOURCE, REVENUE_TYPE } from '../constants';
export const _frontmatter = {
  "title": "Fact Sheet",
  "includeToc": false,
  "permalink": "/fact-sheet/"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Container = makeShortcode("Container");
const Grid = makeShortcode("Grid");
const SnapshotBanner = makeShortcode("SnapshotBanner");
const Box = makeShortcode("Box");
const Typography = makeShortcode("Typography");
const DisbursementsLastTwelveMonths = makeShortcode("DisbursementsLastTwelveMonths");
const RevenueLastTwelveMonths = makeShortcode("RevenueLastTwelveMonths");
const ProductionLastTwelveMonths = makeShortcode("ProductionLastTwelveMonths");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Container id="fact-sheet" style={{
      width: '960px'
    }} mdxType="Container">
  <Grid container mdxType="Grid">
    <Grid item xs={12} mdxType="Grid">
      <SnapshotBanner mdxType="SnapshotBanner" />
    </Grid>
    <Grid item xs={12} mdxType="Grid">
      <Box color="secondary.main" borderBottom={3} mdxType="Box">
        <Typography color="textPrimary" style={{
              fontSize: '1.375rem',
              lineHeight: '2rem',
              fontWeight: '600'
            }} mdxType="Typography">Disbursements</Typography>
      </Box>
    </Grid>
    <Grid item xs={6} mdxType="Grid">
      <Box mb={2} mdxType="Box">
        <Typography style={{
              fontSize: '1rem',
              lineHeight: '1.1rem'
            }} mdxType="Typography">The amount of money paid to federal and local governments and Native Americans.</Typography>
      </Box>
      <Typography style={{
            fontStyle: 'italic',
            fontSize: '1rem',
            lineHeight: '1.1rem'
          }} mdxType="Typography">This number is delayed from revenue because disbursements are made after the money comes in.</Typography>
    </Grid>
    <Grid item xs={6} mdxType="Grid">
      <Box pl={'20px'} pr={'20px'} mdxType="Box">
        <DisbursementsLastTwelveMonths title='Disbursements by recipient' chartHeight={130} skeletonHeight={347} yGroupBy={RECIPIENT} disableInteraction={true} svgTitle='Bar chart displaying the amount of disbursements collected on a monthly basis by recipient, refer to the data table following the chart for detailed data for each bar. [Details available in the Source Data (.csv)](https://revenuedata.doi.gov/downloads/monthly_disbursements.csv/).' mdxType="DisbursementsLastTwelveMonths" />
      </Box>
    </Grid>
    <Grid item xs={12} mdxType="Grid">
      <Box color="secondary.main" borderBottom={3} mdxType="Box">
        <Typography color="textPrimary" style={{
              fontSize: '1.375rem',
              lineHeight: '2rem',
              fontWeight: '600'
            }} mdxType="Typography">Revenue</Typography>
      </Box>
      <Box mb={0} mdxType="Box">
        <Typography color="textPrimary" component='span' style={{
              fontSize: '1rem',
              lineHeight: '0.9rem'
            }} mdxType="Typography">
          The amount of money collected from energy and minerals on property owned by the federal government and Native Americans.
          <Typography style={{
                fontStyle: 'italic',
                fontSize: '1rem',
                lineHeight: '0.9em',
                display: 'inline'
              }} mdxType="Typography">
            {' '}Negative numbers often indicate adjustments because this is accounting data, not sales data.
          </Typography>
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={6} mdxType="Grid">
      <Box pl={'20px'} pr={'20px'} mdxType="Box">
        <RevenueLastTwelveMonths title='Revenue by source' yGroupBy={SOURCE} chartHeight={130} skeletonHeight={293} disableInteraction={true} svgTitle='Bar chart displaying the amount of revenue collected on a monthly basis by source, refer to the data table following the chart for detailed data for each bar.[Details available in the Source Data (.csv)](https://revenuedata.doi.gov/downloads/monthly_revenue.csv/).' mdxType="RevenueLastTwelveMonths" />
      </Box>
    </Grid>
    <Grid item xs={6} mdxType="Grid">
      <Box pl={'20px'} pr={'20px'} mdxType="Box">
        <RevenueLastTwelveMonths title='Revenue by revenue type' yGroupBy={'revenue_type'} chartHeight={130} skeletonHeight={293} disableInteraction={true} svgTitle='Bar chart displaying the amount of revenue collected on a monthly basis by revenue type, refer to the data table following the chart for detailed data for each bar.  [Details available in the Source Data (.csv)](https://revenuedata.doi.gov/downloads/monthly_revenue.csv/).' mdxType="RevenueLastTwelveMonths" />
      </Box>
    </Grid>
    <Grid item xs={12} mdxType="Grid">
      <Box color="secondary.main" borderBottom={3} mdxType="Box">
        <Typography color="textPrimary" style={{
              fontSize: '1.375rem',
              lineHeight: '2rem',
              fontWeight: '600'
            }} mdxType="Typography">Production</Typography>
      </Box>
      <Box mb={0} mdxType="Box">
        <Typography color="textPrimary" component='span' style={{
              fontSize: '1rem',
              lineHeight: '0.9rem'
            }} mdxType="Typography">
          The volume of energy produced on federal and Native American property.
          <Typography style={{
                fontStyle: 'italic',
                fontSize: '1rem',
                lineHeight: '0.9rem',
                display: 'inline'
              }} mdxType="Typography">
            {' '}Production numbers are behind revenue and disbursements to give time for adjustments.
          </Typography>
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={4} mdxType="Grid">
      <Box pl={'10px'} pr={'10px'} mdxType="Box">
        <ProductionLastTwelveMonths title='Oil (bbl)' filterByProduct='Oil (bbl)' units='bbl' yGroupBy={SOURCE} chartHeight={120} skeletonHeight={283} disableInteraction={true} svgTitle='Bar chart displaying the amount of oil produced on a monthly basis, refer to the data table following the chart for detailed data for each bar.  [Details available in the Source Data (.csv)]( https://revenuedata.doi.gov/downloads/monthly_production.csv/).' mdxType="ProductionLastTwelveMonths" />
      </Box>
    </Grid>
    <Grid item xs={4} mdxType="Grid">
      <Box pl={'10px'} pr={'10px'} mdxType="Box">
        <ProductionLastTwelveMonths title='Gas (mcf)' filterByProduct='Gas (mcf)' units='mcf' yGroupBy={SOURCE} chartHeight={120} skeletonHeight={283} disableInteraction={true} svgTitle='Bar chart displaying the amount of gas produced on a monthly basis, refer to the data table following the chart for detailed data for each bar.  [Details available in the Source Data (.csv)]( https://revenuedata.doi.gov/downloads/monthly_production.csv/).' mdxType="ProductionLastTwelveMonths" />
      </Box>
    </Grid>
    <Grid item xs={4} mdxType="Grid">
      <Box pl={'10px'} pr={'10px'} mdxType="Box">
        <ProductionLastTwelveMonths title='Coal (tons)' filterByProduct='Coal (tons)' units='tons' yGroupBy={SOURCE} chartHeight={120} skeletonHeight={283} disableInteraction={true} svgTitle='Bar chart displaying the amount of coal produced on a monthly basis, refer to the data table following the chart for detailed data for each bar.  [Details available in the Source Data (.csv)]( https://revenuedata.doi.gov/downloads/monthly_production.csv/.)' mdxType="ProductionLastTwelveMonths" />
      </Box>
    </Grid>
  </Grid>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      